import { useLocalStorageState } from 'ahooks';
import { modeEnum } from './modeEnum';
import dayjs from 'dayjs';
import type {
	includeCheckIn,
	includeLease,
	includeMonthly,
} from 'server/type-file';

const constantForOverviewState = 'overview-state';
const defaultConfigureValue = {
	mode: modeEnum.roomStatus,
	time: dayjs().valueOf(),
};
type categoryType = [
	typeof includeMonthly,
	typeof includeCheckIn,
	typeof includeLease,
];

export function useOverviewState() {
	return useLocalStorageState<{
		time?: number;
		mode?: modeEnum;
		floor?: string;
		category?: categoryType;
		roomNumber?: number;
	}>(constantForOverviewState, {
		defaultValue: defaultConfigureValue,
	});
}
